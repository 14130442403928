import React from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import Navigation from "../../components/navigation";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import ReactGA from "react-ga";
import { Link } from "gatsby";
import DonationFooter from "../../components/donation-footer";
import CodeSnippet from '../../components/code-snippet'

class View extends React.Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		ReactGA.initialize("UA-115464417-1");
		if (!(window.location.hostname === "localhost")) {
			ReactGA.pageview(window.location.pathname + window.location.search);
		}
    }

	render() {
		return (
			<CodeSnippet

			header={'Doubly Linked List | Code'}
			subHeader={'Doubly Linked List'}
			description={'Doubly Linked List'}
			keywords={['Doubly Linked List']}
			embeddedVideo={''}
			embeddedCode={'https://gist.githubusercontent.com/graphoarty/9bba89456a2687103f64c472a82053a8/raw/32e220a557b06d0bb21df2d6fa48e5f37b407cda/DoublyLinkedList.txt'}		

			></CodeSnippet>
		);
	}
}

export default View;
